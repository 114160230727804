import { EditorState } from 'lexical';
import { $generateHtmlFromNodes } from '@lexical/html';
import { LexicalComposer } from '@lexical/react/LexicalComposer';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import { LinkNode, AutoLinkNode } from '@lexical/link';
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary';
import {
  Box,
  Text
} from '@chakra-ui/react';
import { ToolbarPlugin } from './ToolbarPlugin';

const contentEditableStyle = {
  '[contenteditable="true"]': {
    outline: 'none',
    flex: '1',
    '&:focus': { outline: 'none' },
    '&:focus-visible': { outline: 'none' },
    '& a': {
      color: 'blue.500',
      textDecoration: 'underline',
      '&:hover': {
        color: 'blue.700',
      },
    }
  }
};

type RichTextEditorProps = {
  initialState: string | null;
  setLexical: (value: string) => void;
  setHtml: (value: string) => void;
}

export const RichTextEditor = ({ initialState, setLexical, setHtml }: RichTextEditorProps) => {
  const initialConfig = {
    namespace: 'EmailEditor',
    onError: (error: Error) => console.error(error),
    editorState: initialState,
    nodes: [LinkNode, AutoLinkNode],
  };

  const onChange = (editorState: EditorState, editor) => {
    // Save the serialized state
    const jsonString = JSON.stringify(editorState);
    setLexical(jsonString);

    // Convert to HTML and save
    editor.update(() => {
      const htmlString = $generateHtmlFromNodes(editor);
      setHtml(htmlString);
    });
  };

  const URL_MATCHER = (text: string) => {
    const urlPattern = /(https?:\/\/[^\s]+)/g;
    const match = urlPattern.exec(text);
    return match ? { index: match.index, length: match[0].length, text: match[0], url: match[0] } : null;
  };

  const MATCHERS = [URL_MATCHER];

  return (
    <Box border="1px" borderColor="gray.200" borderRadius="md">
      <LexicalComposer initialConfig={initialConfig}>
        <Box>
          <ToolbarPlugin />
          <Box p={2} sx={contentEditableStyle}>
            <RichTextPlugin
              contentEditable={<ContentEditable />}
              placeholder={<Text color="gray.500">Enter content here...</Text>}
              ErrorBoundary={LexicalErrorBoundary}
            />
            <HistoryPlugin />
            <OnChangePlugin onChange={onChange} />
            <LinkPlugin />
            <AutoLinkPlugin matchers={MATCHERS} />
          </Box>
        </Box>
      </LexicalComposer>
    </Box>
  );
};
