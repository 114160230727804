import {
  Box,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
} from "@chakra-ui/react"
import logo from "../logo.svg"
import { Link as ReactRouterLink, useNavigate } from "react-router-dom"
import { ExternalLinkIcon, HamburgerIcon } from "@chakra-ui/icons"
import useAuth from "../hooks/useAuth"
import useAxiosPrivate from "../hooks/useAxiosPrivate"

export const Nav = () => {
  const { setAuth } = useAuth()
  const axiosPrivate = useAxiosPrivate()
  const navigate = useNavigate()

  const logout = async () => {
    try {
      await axiosPrivate.get('/users/logout')

      setAuth({
        token: '',
        roles: {}
      });
      navigate('/', { state: { from: '/orders' }, replace: true })
    } catch (e: any) {
      console.error(e)
    }
  }

  return (
    <Box bgColor="#1D59A5" boxShadow="dark-lg" h="55px" w="100%" position="absolute" zIndex="2">
      <Box bgColor="#D7D5D1" h="45px" w="100%">
        <HStack>
          <Image bgColor="#0F2C5A" borderRadius="full" boxSize="110" boxShadow="dark-lg" p="10px" src={logo} alt="County Saddlery Logo"/>
          <HStack justifyContent="flex-end" paddingBottom="65px" paddingRight="15px" w="100%">
            <Box>
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  bgColor="#13438A"
                  icon={<HamburgerIcon color="white" />}
                  variant="outline"
                />
                <MenuList>
                  <UserLink route="/customers" label="Customers" />
                  <AdminLink route="/lead-sources" label="Lead Sources" />
                  <UserLink route="/orders" label="Orders" />
                  <AdminLink route="/products" label="Products" />
                  <AdminLink route="/saddlers" label="Saddlers" />
                  <AdminLink route="/email-templates" label="Email Templates" />
                  <AdminLink route="/users" label="Users" />
                  <MenuDivider />
                  <MenuItem icon={<ExternalLinkIcon />} onClick={() => logout()}>
                    Log out
                  </MenuItem>
                </MenuList>
              </Menu>
            </Box>
          </HStack>
        </HStack>
      </Box>
    </Box>
  )
}

type userLinkProps = {
  route: string
  label: string
}

const UserLink = ({ route, label }: userLinkProps) => {
  const { auth } = useAuth()
  const Roles: any = auth.roles

  return <RoleLink route={route} label={label} roles={[Roles.Super, Roles.Admin, Roles.User]} />
}

type adminLinkProps = {
  route: string
  label: string
}

const AdminLink = ({ route, label }: adminLinkProps) => {
  const { auth } = useAuth()
  const Roles: any = auth.roles

  return <RoleLink route={route} label={label} roles={[Roles.Super, Roles.Admin]} />
}

type superAdminLinkProps = {
  route: string
  label: string
}

const SuperAdminLink = ({ route, label }: superAdminLinkProps) => {
  const { auth } = useAuth()
  const Roles: any = auth.roles

  return <RoleLink route={route} label={label} roles={[Roles.Super]} />
}

type roleLinkProps = {
  route: string
  label: string
  roles: number[]
}

const RoleLink = ({ route, label, roles }: roleLinkProps) => {
  const { auth } = useAuth()

  if (auth?.user?.role === undefined || (typeof auth?.user?.role !== undefined && !roles.includes(auth?.user?.role))) {
    return null
  }

  return (
    <MenuItem as={ReactRouterLink} to={route}>
      {label}
    </MenuItem>
  )
}
