import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { FORMAT_TEXT_COMMAND, FORMAT_ELEMENT_COMMAND, ElementFormatType, $getSelection, $isRangeSelection, $createTextNode } from 'lexical';
import { $createLinkNode, $isLinkNode } from '@lexical/link';
import {
  Box,
  ButtonGroup,
  IconButton,
  Tooltip,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Input,
  Button,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem
} from '@chakra-ui/react';
import {
  FaBold,
  FaItalic,
  FaUnderline,
  FaAlignLeft,
  FaAlignCenter,
  FaAlignRight,
  FaAlignJustify,
  FaLink,
  FaTags
} from 'react-icons/fa';
import { useState } from 'react';

const TEMPLATE_TAGS = [
  { label: 'Order Number', value: 'order_number' },
  { label: 'Customer Name', value: 'customer_name' },
  { label: 'Product Name', value: 'product_name' },
  { label: 'Seat Size', value: 'seat_size' },
  { label: 'Tree Size', value: 'tree_size' },
  { label: 'Color and Type of Leather', value: 'color_and_type_of_leather' },
];

export const ToolbarPlugin = () => {
  const [editor] = useLexicalComposerContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [url, setUrl] = useState('');
  const [selectedText, setSelectedText] = useState('');

  const formatText = (format: 'bold' | 'italic' | 'underline') => {
    editor.dispatchCommand(FORMAT_TEXT_COMMAND, format);
  };

  const formatAlignment = (alignment: ElementFormatType) => {
    editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, alignment);
  };

  const insertLink = () => {
    if (!url) return;

    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        // If there's an existing link node, update its URL
        const node = selection.anchor.getNode();
        const parent = node.getParent();
        if ($isLinkNode(parent)) {
          parent.setURL(url);
        } else {
          // Create a new link with the selected text
          const linkNode = $createLinkNode(url);
          // Get the selected text and create a text node
          const textContent = selection.getTextContent();
          if (textContent) {
            const textNode = $createTextNode(textContent);
            linkNode.append(textNode);
          }
          selection.insertNodes([linkNode]);
        }
      }
    });

    setUrl('');
    onClose();
  };

  const handleLinkButton = () => {
    editor.getEditorState().read(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const node = selection.anchor.getNode();
        const parent = node.getParent();
        if ($isLinkNode(parent)) {
          setUrl(parent.getURL());
        }
        setSelectedText(selection.getTextContent());
        onOpen();
      }
    });
  };

  const insertTemplateTag = (tagValue: string) => {
    editor.update(() => {
      const selection = $getSelection();
      if ($isRangeSelection(selection)) {
        const textNode = $createTextNode(`{{${tagValue}}}`);
        selection.insertNodes([textNode]);
      }
    });
  };

  return (
    <>
      <Box borderBottom="1px" borderColor="gray.200" p={2}>
        <ButtonGroup spacing={1} size="sm">
          <Tooltip label="Bold">
            <IconButton
              aria-label="Format Bold"
              icon={<FaBold />}
              onClick={() => formatText('bold')}
            />
          </Tooltip>
          <Tooltip label="Italic">
            <IconButton
              aria-label="Format Italic"
              icon={<FaItalic />}
              onClick={() => formatText('italic')}
            />
          </Tooltip>
          <Tooltip label="Underline">
            <IconButton
              aria-label="Format Underline"
              icon={<FaUnderline />}
              onClick={() => formatText('underline')}
            />
          </Tooltip>

          <Box borderRight="1px" borderColor="gray.200" mr={2} pl={2}/>

          <Tooltip label="Align Left">
            <IconButton
              aria-label="Align Left"
              icon={<FaAlignLeft />}
              onClick={() => formatAlignment('left')}
            />
          </Tooltip>
          <Tooltip label="Align Center">
            <IconButton
              aria-label="Align Center"
              icon={<FaAlignCenter />}
              onClick={() => formatAlignment('center')}
            />
          </Tooltip>
          <Tooltip label="Align Right">
            <IconButton
              aria-label="Align Right"
              icon={<FaAlignRight />}
              onClick={() => formatAlignment('right')}
            />
          </Tooltip>
          <Tooltip label="Justify">
            <IconButton
              aria-label="Justify"
              icon={<FaAlignJustify />}
              onClick={() => formatAlignment('justify')}
            />
          </Tooltip>

          <Box borderRight="1px" borderColor="gray.200" mr={2} pl={2} />

          <Tooltip label="Add Link">
            <IconButton
              aria-label="Add Link"
              icon={<FaLink />}
              onClick={handleLinkButton}
            />
          </Tooltip>

          <Menu>
            <Tooltip label="Insert Template Tag">
              <MenuButton
                as={IconButton}
                aria-label="Insert Template Tag"
                icon={<FaTags />}
              />
            </Tooltip>
            <MenuList>
              {TEMPLATE_TAGS.map((tag) => (
                <MenuItem
                  key={tag.value}
                  onClick={() => insertTemplateTag(tag.value)}
                >
                  {tag.label}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        </ButtonGroup>
      </Box>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Link</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack spacing={4}>
              <Input
                placeholder="Enter URL"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
              />
              <Button colorScheme="blue" onClick={insertLink} width="full">
                Add Link
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
