import { useState, useEffect } from 'react';
import {
  Button,
  Box,
  Heading,
  Spinner,
  Center,
  Input,
  FormControl,
  Card,
  useToast
} from '@chakra-ui/react';

import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { NavLayout } from '../components/NavLayout';
import { RichTextEditor } from '../components/RichTextEditor';

interface EmailTemplate {
  id: number;
  slug: string;
  subject: string;
  lexical: string;
  html: string;
  created: string;
}

export default function EmailTemplates() {
  const axiosPrivate = useAxiosPrivate()

  const [templates, setTemplates] = useState<EmailTemplate[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTemplates = async () => {
      const controller = new AbortController()
      try {
        const response = await axiosPrivate.get('/email-templates/index', {
          signal: controller.signal
        })
        setTemplates(response.data.emailTemplates)
      } catch (err) {
        console.error('Error fetching email templates:', err);
      } finally {
        setLoading(false);
      }
    }
    fetchTemplates();
  }, [axiosPrivate]);

  if (loading) {
    return (
      <Center h="200px">
        <Spinner size="xl" />
      </Center>
    );
  }

  return (
    <NavLayout
      left={
        <Heading as="h1" size="lg">Email Templates</Heading>
      }
      card={false}
    >
      <Box display="flex" flexDirection="column" gap="2em" w="100%" pb="5em">
        {templates.map((template) => (
          <EditTemplate key={template.id} template={template} />
        ))}
      </Box>
    </NavLayout>
  );
}

type EditTemplateProps = {
  template: EmailTemplate
}

const EditTemplate = ({template}: EditTemplateProps) => {
  const axiosPrivate = useAxiosPrivate()
  const toast = useToast()

  const [subject, setSubject] = useState(template.subject);
  const [lexical, setLexical] = useState(template.lexical);
  const [html, setHtml] = useState(template.html);

  const handleSave = () => {
    axiosPrivate.patch(`/email-templates/edit/${template.slug}`, {
      subject,
      lexical,
      html
    })
    .then(() => {
      toast({
        title: 'Success',
        description: 'Email template successfully updated!',
        status: 'success',
        isClosable: true,
      })
    })
    .catch(() => {
      toast({
        title: 'Error',
        description: 'An error occurred while saving the email template',
        status: 'error',
        isClosable: true,
      })
    });
  };

  return (
    <Card display="flex" flexDirection="column" gap="2em" p="2em">
      <Heading as="h2" size="md">
        {template.slug.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
      </Heading>
      <FormControl>
        <Input type='text' value={subject} onChange={(e) => setSubject(e.target.value)} />
      </FormControl>
      <RichTextEditor
        initialState={template.lexical}
        setLexical={setLexical}
        setHtml={setHtml}
      />
      <Button onClick={handleSave}>Save</Button>
    </Card>
  );
};
